.customTooltip {
  /* background-color: #e3e6ea;
  color: #ffffff; 
  border: #e3e6ea;
  padding: '10px'; */
}

.spinnerLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: 'translate(-50%, -50%)';
  z-index: 1000;
}
