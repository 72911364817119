/* Define styles for the compact table */
.compactTable {
  font-size: 13px; /* Reduce font size */
  width: 70% !important;
  margin: auto;
}

// /* Reduce padding for table headers and cells */
.compactTable th,
.compactTable td {
  padding: 0.25rem 0.5rem;
}

/* Style the table header */
.compactTable1 thead {
  padding-bottom: 0 !important;
}

/* Style table header cells */
.compactTable th {
  text-align: left; /* Left-align header text */
}

.centerItems {
  justify-content: center;
  align-items: center;
  // min-width: 100vh;
}
.finalText {
  font-size: small;
  text-align: justify;
}
