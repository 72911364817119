.instruction {
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.question {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
}

.flicker {
  animation: flickerAnimation 0.5s infinite;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.colQuestionImage {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionImage {
  font-size: 16px;
  text-align: start;
  max-width: 300px;
  max-height: 400px;
}

.passageText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: left;
  text-align: justify;
  flex-direction: column;
  justify-content: center;
}

.passageTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.answerOptions {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 50% !important;
}

.errorMessage {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
