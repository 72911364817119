.instruction {
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.question {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.answerOptions {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 50% !important;
}

.errorMessage {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
