.instruction {
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.question {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.colQuestionImage {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionImage {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 300px;
  max-height: 400px;
}

.writeText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  resize: none;
  border-width: 2px !important;
}

.answerOptions {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 50% !important;
}
.countWords {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: end;
}
.errorMessage {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
