.rowUserData {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  margin-left: 2px;
}
.firstColUserData {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

@media screen and (min-width: 10px) and (max-width: 767px) {
  .colLevel {
    font-size: small;
    text-align: center;
  }

  .colQuestion {
    text-align: justify;
    font-size: small;
  }

  .colAnswer {
    margin-top: 10px !important;
    text-align: center;
    margin-bottom: 5px;
  }

  .colScore {
    border-bottom: 1px solid #d8e2ef;
  }
  .colCalificar {
    border-bottom: 1px solid #d8e2ef;
  }

  .inputCalificar {
    width: -webkit-fill-available;
  }
  .btnCalificar {
    font-size: small;
    width: -webkit-fill-available;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .colLevel {
    font-size: medium;
    text-align: center;
  }

  .colQuestion {
    text-align: justify;
    font-size: medium;
  }

  .colAnswer {
    margin-top: 10px !important;
    text-align: center;
    margin-bottom: 5px;
  }

  .colScore {
    border-bottom: 1px solid #d8e2ef;
  }
  .colCalificar {
    border-bottom: 1px solid #d8e2ef;
  }

  .inputCalificar {
    font-size: medium;
    width: -webkit-fill-available;
  }
  .btnCalificar {
    font-size: medium;
    width: -webkit-fill-available;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .colAnswer {
    margin-top: 20px !important;
    border-bottom: 1px solid #d8e2ef;
  }

  .colScore {
    margin-top: 20px !important;
    border-bottom: 1px solid #d8e2ef;
  }
  .colCalificar {
    margin-top: 20px !important;
    border-bottom: 1px solid #d8e2ef;
  }
}

@media screen and (min-width: 992px) and (max-width: 3000px) {
  .colAnswer {
    margin-top: 20px !important;
    border-bottom: 1px solid #d8e2ef;
  }

  .colScore {
    margin-top: 20px !important;
    border-bottom: 1px solid #d8e2ef;
  }
  .colCalificar {
    margin-top: 20px !important;
    border-bottom: 1px solid #d8e2ef;
    padding-bottom: 40px !important;
  }
}
