.instruction {
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.colQuestionImage {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionImage {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 300px;
  max-height: 400px;
}

.audio {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answerOptions {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 100% !important;
}

.errorMessage {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
